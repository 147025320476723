<template>
<div class="confirm">
<div class="container">
<h2 class="thank_text text-center">Thank You !</h2>
<p class="text_common text-center">Thank you for fill up the KYC form on <span class="color_primary">jpremit.com.</span>  You will received an email massage shortly.</p>
<div class="img-right text-center">
<img src="@/assets/img/right_sing_thanks.png" class="img-fluid" alt="" srcset=""></div>
</div>
<div class="button text-center">
<router-link to="/" href="" class="btn btn-danger btn_back_home button_text">Back To Home Page</router-link>
</div>
    </div>
</template>
<style lang="scss">
.confirm{
    padding-top: 90px;
    padding-bottom: 150px;
    .img-right{
margin-top:35px;    
}
.thank_text{
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 45px;
text-align: center;
color: #181818;
margin-bottom: 24px;

}
.color_primary{
color: #9A1C22;
}
.text_common{
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #181818;
}
.btn_back_home{
background: #BD202E;
border-radius: 11px !important;
padding:14px 62px !important;
}

}
@media (max-width:800px){
  .confirm{
  .thank_text{
    font-size: 48px;
    line-height: 35px;
  }
  .text_common{
    font-size: 16px;
    line-height: 18px;
    margin-top: 30px;
  }
  }
}

</style>
<script>
export default {
    name: 'ConfrimPage',
    components: {
  
  
    },
    mounted() {
      this.updateDataProp();
    },
    methods: {
      updateDataProp() {
  
        this.$emit('update:data', true);
      }
    }
  }
  </script>