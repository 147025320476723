<template>
  <div class="login_section">
  {{ receivedData  }}
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <div class="back d-flex align-items-center" @click="goBack">
            <i class="fa-solid fa-arrow-left arrow_back"></i>
            <p class="text_back">Back</p>
          </div>
        </div>
        <div class="col-md-10 mt-3 mt-lg-0 mt-md-0">
          <h2 class="heading text-center">Which type of account are you interested in opening today?</h2>
          <p class="title text-center">(Must Select One)</p>
     <div class="form">
       <form>
         <div class="row mb-30">
           <div class="col-md-12">
             <label class="label_text">Email / Phone Number *</label>
             <input type="text" class="form-control input_custom" placeholder="Enter your email / phone number" />
           </div>
         </div>
           <div class="row mb-30">
             <div class="col-md-6 mbr-30">
               <label class="label_text">ID Card Number</label>
               <input class="form-control input_custom" type="text" placeholder="Enter your email / phone number" />
             </div>
             <div class="col-md-6">
               <label class="label_text">Date of Birth (DOB)</label>
               <input class="form-control input_custom date_picker" type="date" placeholder="MM/DD/YYYY" />
             </div>
         </div>
         <div class="row">
           <div class="col-md-12">
             <router-link :to="type" type="submit" class="btn btn-danger button_text button_text d-block btn_submit">Next</router-link>
           </div>
         </div>
       </form>
     </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>


export default {
  data() {
    return {
      type: '',
    };
  },
  created() {
    var acc= this.retrievedData = localStorage.getItem('myData');
    if(acc=='personal'){
      this.type='/individual-from';
    }else{
      this.type='/bussiness-from';
    }
    console.log(this.type)

  },
  methods:{
      
    goBack() {
      window.history.back();
    
  }
}
};
</script>
<style lang="scss">
.login_section{
  margin-top: 50px;
  margin-bottom: 50px;
  .mb-30{
    margin-bottom:30px;
  }

 .form{
  .label_text{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #181818;
    margin-bottom:16px;
  }
   .form-control:focus {
     border: 1px solid #A3A3A3 !important;
     box-shadow: none !important;
   }
   .input_custom{
     border: 1px solid #A3A3A3;
     font-family: 'Raleway';
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 19px;
     color: #A3A3A3;
     border-radius: 9px;
     height: 54px;
   }
   .date_picker{
     background:transparent url('../assets/img/datepicker.png');
     position: relative;
     background-repeat:no-repeat;
     background-position: 100%;

   }
   .date_picker::-webkit-calendar-picker-indicator{
     opacity:0;
     width: 100px;
     position: absolute;
     right: -50px;
     height: 100px;
     background: red;
     z-index: 99;
   }
   .btn_submit{
     background: #BD202E;
     border-radius: 9px;
   }


 }
}
@media (max-width:1200px){
  .login_section {
    .mbr-30{
    margin-bottom: 30px;
  }
    .form {
      .label_text{
    font-size: 16px;
    line-height: 19px;
  }
  .input_custom{
    font-size: 16px;
    line-height: 18px;
    height: 48px;
  }
    }
 
  }
 
}
@media (max-width:800px){
  .login_section{
    
.form {
  .label_text{
    font-size: 16px;
    line-height: 18px;
  }
  .input_custom{
    font-size: 14px;
    line-height: 16px;
  }
}
  }
}
</style>