<template>
  <div class="footer">
  <div class="container">
    <h3 class="section_heading">Call For Help</h3>
    <div class="row">
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Head Office</h4>
            <h4 class="number">092-292-7960</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>

      <div class="col-md-6 mb-20">
      <div class="help d-flex justify-content-between align-items-center">
      <div class="text">
       <h4 class="text_office">Fukuoka Office</h4>
       <h4 class="number">03-5733-4337</h4>
         </div>
         <div class="icon">
           <img src="@/assets/img/icon_phone.png" class="img-fluid">
         </div>

</div>
    </div>
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Roppongi Office</h4>
            <h4 class="number">03-5733-4337</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Ikebukuro Office</h4>
            <h4 class="number">03-5733-4337</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Okubo Office</h4>
            <h4 class="number">03-5733-4337</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Shin Okubo Office</h4>
            <h4 class="number">03-5733-4337</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>
      <div class="col-md-6 mb-20">
        <div class="help d-flex justify-content-between align-items-center">
          <div class="text">
            <h4 class="text_office">Nagoya Office</h4>
            <h4 class="number">03-5733-4337</h4>
          </div>
          <div class="icon">
            <img src="@/assets/img/icon_phone.png" class="img-fluid">
          </div>

        </div>
      </div>
    </div>

  </div>
  </div>


</template>
<style lang="scss">
/* css for help component */
.footer {
  .mb-20{
    margin-bottom:20px;
  }
  padding-top: 51px;
  padding-bottom: 65px;
  background: #FAFAFA;
  .section_heading{
    margin-bottom: 31px;
    text-align:center;
  }
  .section_heading {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #181818;
  }

  .help {
    background: #F5F4F6;
    border-radius: 7px;
    padding: 10px 24px;

    .text {
      .text_office {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #181818;
      }

      .number {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #757575;
      }
    }
  }
}
@media (max-width:1200px){
  .footer{
    .section_heading{
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .help{
    .text{
      .text_office{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  }

}
</style>