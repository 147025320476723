<template>
  <div class="language_change">
  <div class="container">
    <div class="row">
    <div class="col-md-2">
      <div class="back d-flex align-items-center" @click="goBack">
      <i class="fa-solid fa-arrow-left arrow_back"></i>
        <p class="text_back">Back</p>
      </div>
    </div>
    <div class="col-md-10">
      <h2 class="heading text-center">What is Your Preferred Language And Location</h2>
      <p class="title text-center">(Must Select One)</p>
      <div class="d-flex flex-wrap language ">
        <div class="item text-center "  v-for="(item, index) in items" :key="index" :class="{'active': activeItem === index}" @click="activateItem(index)">
        <img v-if="activeItem==index" src="@/assets/img/rt_icon.png" alt="" class="img-fluid right-icon">
          <img class="img_fluid" :src="item.src">
          <p class="text_country">{{ item.lang }}</p>
        </div>
   
      </div>
      <div class="button text-center">
        <router-link to="/account-type" class="btn btn-danger btn_next button_text" >Next</router-link>
      </div>
    </div>
    </div>
    </div>


  </div>
</template>
<script>
// import font-awesome icon
import '@fortawesome/fontawesome-free/css/all.css'
import usaImg from '@/assets/img/usa.png';
import jpImg from '@/assets/img/jp.png';
import vtImg from '@/assets/img/vt.png';
import chImg from '@/assets/img/ch.png';
import spImg from '@/assets/img/sp.png';
export default {
  name: 'LanguageChange',
  components: {


  },
  data(){
return {
  items: [
        { lang: 'English', src:usaImg },
        { lang: 'Japanese', src:jpImg },
        { lang: 'Vietnamese', src:vtImg },
        { lang: 'Chinese', src:chImg },
        { lang: 'Spanish', src:spImg },
      
  
      ],
      activeItem: null,
      flag:false

}
  },
  methods:{
    goBack() {
      window.history.back();
    },
    activateItem(index) {
   this.flag = true;
      this.activeItem = index;
    }
  }

}
</script>
<style lang="scss">

.language_change{
  margin-top:70px;
  margin-bottom: 70px;

  .language{
    margin-top:68px;
    .active{
    background: #F9E9EB !important;
    border: 1px solid #BD202E !important;

.text_country{

}
  }
    .item{
      background: #F3F3F3;
      border-radius: 9px;
      padding: 16px 60px;
      position: relative;
      border:1px solid transparent;
      .right-icon{
        position:absolute;
        left:10px;
      }
      .text_country{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #181818;
        margin-top:14px;
      }
      &:not(:last-child){
      margin-right: 20px;
    }
    }
  }
.button{
  margin-top:50px;
  .btn_next{
    padding:17px 92px;
    background: #BD202E;
    border-radius: 9px;
  }

}
}
@media (max-width:1200px){
  .language_change{
    .language{
      .item{
       width: 45%;
        margin-top:30px;
        padding: 10px 45px;
        .text_country{
          font-size: 15px;
          line-height: 14px;
        }
      }

    }
  }

}
@media (max-width:1400px){
  .language_change{
    .language{
      .item{
        padding: 16px 49px;
      }
    }
  }
}
@media (max-width:500px){
  .language_change{
  .language{
    .item{
      width: 42%;
    }
  }
  }
}
</style>