import { createRouter, createWebHistory } from 'vue-router'
import LanguageChange from './views/LanguageChange.vue'
import AccountType from './views/AccountType.vue'
import LoginPage from './views/LoginPage.vue'
import IndividualFrom from './views/IndividualFrom.vue'
import BussinessPage from './views/BussinessPage.vue'
import ConfirmPage from './views/ConfirmPage.vue'
const routes = [
    {
        path: '/',
        name: 'language',
        component: LanguageChange
    },
    {
        path: '/account-type',
        name: 'Account',
        component:AccountType
    },
    {
        path: '/login',
        name: 'type',
        component: LoginPage
    },
    {
        path: '/individual-from',
        name: 'individualFrom',
        component: IndividualFrom
    },
    {
        path: '/bussiness-from',
        name: 'BussinessPage',
        component: BussinessPage
    },
    {
        path: '/confrim',
        name: 'ConfrimPage',
        component: ConfirmPage
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
