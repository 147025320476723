<template>
    <div class="business">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <div class="back d-flex align-items-center" @click="goBack">
              <i class="fa-solid fa-arrow-left arrow_back"></i>
              <p class="text_back">Back</p>
            </div>
          </div>
          <div class="col-md-10">
            <h2 class="heading text-center">Which type of account are you interested in opening today?</h2>
            <p class="title text-center">(Must Select One)</p>
            <div class="form">
              <form>
                <div class="row mb-24">
                  <div class="col-md-12">
                    <label class="label_text">Company Name  <span class="text_primary">*</span></label>
                    <input type="text" class="form-control input_custom" placeholder="Enter your full name" />
                  </div>
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24">
                    <label class="label_text">Date of Establishment <span class="text_primary">*</span> </label>
                    <input class="form-control input_custom date_picker" type="date" placeholder="MM/DD/YYYY" />
                  </div>
                  <div class="col-md-6">
                    <label class="label_text">Date Entered  <span class="text_primary">*</span> </label>
                    <input class="form-control input_custom date_picker" type="date" placeholder="MM/DD/YYYY" />
                  </div>
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24">
                    <label class="label_text">Address <span class="text_primary">*</span> </label>
                    <input class="form-control input_custom " type="text" placeholder="Street, house/apartment" />
                  </div>
                  <div class="col-md-6 ">
                    <label class="label_text">Any changes  <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24">
                    <label class="label_text">Representative`s Name <span class="text_primary">*</span> </label>
                    <input class="form-control input_custom " type="text" placeholder="Enter representative name" />
                  </div>
                  <div class="col-md-6 ">
                    <label class="label_text">Any changes  <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
                </div>
                <div class="row mb-24">
                  <div class="col-md-12">
                    <label class="label_text">Business Outline  <span class="text_primary">*</span> </label>
                    <input class="form-control input_custom " type="text" placeholder="Business Outline" />
                  </div>
         
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24 ">
                    <label class="label_text">TEL/Cellular Phone No.  <span class="text_primary">*</span> </label>
                    <div class="input-group  mb-3">
                      <select class="form-select bg-dangers button_text  select_custom" aria-label="Select input with text">
                        <option selected >+880</option>
                        <option value="+880">+880</option>
                        <option value="+430">+430</option>
                        <option value="+990">+990</option>
                      </select>
                      <input type="text" class="form-control input_custom" placeholder="xxxxxxxxxxxxxx" aria-label="Text input with select button">
                    </div>
                  </div>
                  <div class="col-md-6 ">
                    <label class="label_text">Annual sales (JPY)  <span class="text_primary">*</span> </label>
                    <input type="text" class="form-control input_custom" placeholder="jpy" aria-label="Text input with select button">
                  </div>
  
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24 ">
                    <label class="label_text">Purpose of Remit  <span class="text_primary">*</span> </label>
                 
                     
                    <select class="form-select  button_text color custom_select" aria-label="Select input with text">
                      <option selected  class="option_text_selected">Select Your Remit Purpose</option>
                      <option   class="option_text_selected">Trade</option>
                      <option   class="option_text_selected">Family Maintenance</option>
                      <option   class="option_text_selected">Salary payment</option>
                      <option   class="option_text_selected">Non Trade Expenses</option>
                      <option   class="option_text_selected">Other(Specify)</option>
                      </select>
                 
                  </div>
                  <div class="col-md-6 ">
                    <label class="label_text">Source of Funds  <span class="text_primary">*</span> </label>
                 
                    <select class="form-select  button_text color custom_select" aria-label="Select input with text">
                      <option selected  class="option_text_selected">Select Your Remit Purpose</option>
                      <option   class="option_text_selected">Business Income</option>
                      <option   class="option_text_selected">Other(Specify)</option>
                   
                      </select>
               
                 
                  </div>
  
                </div>
               
                <div class="row mb-24">
                    <div class="col-md-3 mbr-24 ">
                    <label class="label_text label_text_custom">Politically Exposed Person   <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
                <div class="col-md-3 mbr-24 ">
                    <label class="label_text label_text_custom">Any changes(Politically Exposed Person)   <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
                <div class="col-md-3 mbr-24 ">
                    <label class="label_text label_text_custom">Ultimate Beneficiary    <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
                <div class="col-md-3 ">
                    <label class="label_text label_text_custom">Any changes(Ultimate Beneficiary)   <span class="text_primary">*</span> </label>
                    <div class="d-flex height-50 align-items-center">
                  <div class="form-check">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">Yes</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                <div class="form-check ms-3">
                 <input class="form-check-input cust1" type="checkbox" id="check1" name="option1" value="something" >
                 <label class="form-check-label text_check_label ms-2">No</label>
                 <input class="form-check-input cust1" type="checkbox" id="check2" name="option2" value="something" >
                 </div> 
                </div>   
                </div>
  
                </div>
                <div class="row mb-24">
                  <div class="col-md-6 mbr-24">,
                    <input class="form-control input_custom " type="text" placeholder="Please enter the changes" />
                  </div>
                  <div class="col-md-6">,
                    <input class="form-control input_custom " type="text" placeholder="Please enter the changes" />
                  </div>
                </div>
                <div class="row mt-52">
                  <div class="col-md-12">
                    <router-link to="/confrim" type="submit" class="btn btn-danger button_text button_text d-block btn_submit">Confirm</router-link>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'BusinessPage',
    components: {
  
  
    },
    mounted() {
    this.updateDataProp();
  },
  methods: {
    goBack() {
      window.history.back();
    },
    updateDataProp() {

      this.$emit('update:data', true);
    }
  }
  }
  </script>
  <style lang="scss">
  .business{
    margin-top: 57px;
    margin-bottom:80px;
    .height-50{
        height: 50%;

    }
    .option_text{
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #181818;
  }
  .option_text_selected{
font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #181818;
  }
  .form-check-input[type=checkbox] {
    height:20px;
    width:20px;
    border: 1px solid #181818;
    border-radius: 2px;
  }
  
  .form-check-input:focus {
    box-shadow:none;
  }
    .mb-24{
      margin-bottom:24px;
    }
    .mt-52{
      margin-top:52px;
    }
    .form{
      .label_text{
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #181818;
        margin-bottom:16px;
      }
      .label_text_custom{
        font-weight: 700;
        font-size: 10px;
      }
      .button_text{
        color:#fff;
      }
      .text_check_label{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #181818;
      }
      .form-control:focus {
        border: 1px solid #A3A3A3 !important;
        box-shadow: none !important;
      }
      .input_custom{
        border: 1px solid #A3A3A3;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #A3A3A3;
        border-radius: 9px;
        height: 54px;
      }
      .date_picker{
        background:transparent url('../assets/img/datepicker.png');
        position: relative;
        background-repeat:no-repeat;
        background-position: 100%;
  
      }
      .date_picker::-webkit-calendar-picker-indicator{
        opacity:0;
        width: 100px;
        position: absolute;
        right: -50px;
        height: 100px;
        background: red;
        z-index: 99;
      }
      .btn_submit{
        background: #BD202E;
        border-radius: 9px;
        padding:17px;
      }
      .color{
        color: #A3A3A3;
      }
      .select_custom{
        background-image: url('../assets/img/arrow_down.png') !important;
        background-repeat: no-repeat !important;
        background-position:90% !important;
        max-width: 85px;
        height: 54px;
     
        &:focus{
          box-shadow: none;
        }
     
      }
      .custom_select{
        border: 1px solid #A3A3A3;
         border-radius: 9px;
         height: 54px;
         &:focus{
          box-shadow: none;
        }
      }
   .bg-dangers{
    background: #9A1C22;
    border: 1px solid #9A1C22;
   }
  
    }
  }
  @media (max-width:800px){
  .business{
    
.form {
  .label_text{
    font-size: 16px;
    line-height: 18px;
  }
  .input_custom{
    font-size: 14px;
    line-height: 16px;
  }
}
  }
}
  </style>