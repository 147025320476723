<template>
  <div class="container">
  <div class="copyright">
    <h4 class="copyright_text text-center">© 2023 JRF. All rights reserved</h4>
  </div>
    <div class="scroller" @click="scrollToTop">
      <div class="scroller_area">    <i class="fas fa-angle-up icon_arrow"></i></div>
    </div>
  </div>
</template>
<style lang="scss">
/* css for copyright component */
.copyright{
  margin-top: 65px;
  margin-bottom: 65px;
  .copyright_text{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #757575;
  }

}
.scroller{
  position: relative;
  .scroller_area{
    position: absolute;
    right: 0px;
    bottom: 70px;
    background: #BD202E;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .icon_arrow{

      font-size: 15px;
      color:#fff;
    }
  }

}
</style>
<script>
// import font-awesome icon
import '@fortawesome/fontawesome-free/css/all.css'
export default {
  name: 'CopyRight',
  components: {


  },
  methods: {
    scrollToTop() {
      const scrollToTop = () => {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        if (currentPosition > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, currentPosition - currentPosition / 8);
        }
      };
      scrollToTop();
    }
  }
}
</script>