<template>
<nav class="navbar    navbar-light bg-light"  :class="[{ 'navbar-expand-lg': login, 'navbar-expand':!login }]">
<div class="container">
  <a class="navbar-brand" href="#">
    <img src="@/assets/img/cdd_logo.png" class="img-fluid"/>
  </a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" v-if=login id="navbarSupportedContent">
    <div class=" ms-auto mt-4 mb-mt-0 mt-lg-0">
<h4 class="text_normal">資金移動業者 「関東財務局長 第00024号</h4>
<p class="text_right">Registration Number: No. 00024 Director General of Kanto Local Financial Bureau</p>
    </div>

  </div>
</div>
</nav>
  </template>
<style lang="scss">
/* css code here */
/* navbar  custom  css */
.navbar{

  padding-x:0px !important;
  padding-y:0px !important;
  padding:20px 0px  !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
  .text_normal{
 font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: right;
color: #181818;
margin-top: 10px;

  }
  .text_right{
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
text-align: right;
color: #181818;
  }
}
</style>
<script>
import { reactive } from 'vue';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    console.log(props.show)
    const data = reactive({
      login: props.show
    });

    // You can also modify the value of the `login` data property within the `setup()` function if needed.

    return {
      ...data
    };
  },

  mounted() {
    this.updateDataProp();
  },
  data(){
return{
  expandNavbar: true,
}
  },
  methods: {
    updateDataProp() {

      this.$emit('update:data', false);
    }
  }
};
</script>
