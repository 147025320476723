<template>
  <div class="account_type">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <div class="back d-flex align-items-center" @click="goBack">
            <i class="fa-solid fa-arrow-left arrow_back"></i>
            <p class="text_back">Back</p>
          </div>
        </div>
        <div class="col-md-10">
          <h2 class="heading text-center">Which type of account are you interested in opening today?</h2>
          <p class="title text-center">(Must Select One)</p>
       <div class="d-md-flex justify-content-center button">
          <div class=" text-center">
            <router-link to="/login" class="btn btn-danger btn_type btn_bg_personal button_text" @click="passData('personal')">Personal Account</router-link>
          </div>
         <div class="text-center ml-24">
           <router-link to="/login" class="btn btn-danger btn_type btn_bg_business button_text" @click="passData('business')" >Business Account</router-link>
         </div>
       </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
// import font-awesome icon
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  name: 'AccountType',
  components: {


  },
  methods:{
    passData(value) {
    
      localStorage.setItem('myData',value);
    },
    goBack() {
      window.history.back();
    },
  }
}
</script>
<style lang="scss">
.account_type{
  margin-top:70px;
.ml-24{
  margin-left:24px;
}
  .button{
    margin-top:50px;
    margin-bottom:80px;
    .btn_type{
      padding:13px 41px;
      background: #BD202E;
      border-radius: 11px;
      border:none;
      .btn_bg_personal{
        background: #BD202E;
      }
    }
    .btn_bg_business{
      background: #46B8DA;
    }

  }
}
@media (max-width:800px){
 .account_type{
  .ml-24{
    margin-top: 20px;
    margin-left: 0px;;
  }
 }
}
</style>