import { createApp } from 'vue'
//import vue for initial vue
import App from './App.vue'
//import vue for App  component for render single page application
import router from './router'
//import router for routing
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// import { provideEventBus } from './eventBus';
//import bootstrap css library for make design easy
const app = createApp(App)
// provideEventBus(App);
app.use(router)
app.mount('#app')
//start app in App.vue