<template>
  <div>
<HeaderComponent :show="login" :key="headerKey" />
    <router-view @update:data="updateData"></router-view>
    <HelpComponent />
    <CopyRightComponent />
  </div>
</template>
<style>
/* import font Raleway */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
.arrow_back{
  height:30px;
  margin-right: 15px;
  transform: scale(1.5,1);
}
.text_primary{
  color:#9A1C22;
  font-size: 18px;
}
.text_back{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #181818;
}
.button_text{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}
.heading{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #181818;
  margin-bottom: 36px;
}

.title{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}
/* responsive designe mobile */
@media (max-width:1200px){
  .heading{
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 30px;
  }
}
@media (max-width:800px){
  .mbr-24{
    margin-bottom: 24px;
  }
}
</style>
<script>
//import fixed component header footer and copyright
import HeaderComponent from  './components/HeaderComponent';
import HelpComponent from  './components/HelpComponent';
import CopyRightComponent from  './components/CopyRightComponent';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    HelpComponent,
    CopyRightComponent


  },
  data(){
    return {
      login:false,
      headerKey:1 
    }

  },
  methods: {
    updateData(newValue) {
      console.log(newValue)
      this.login = newValue;
      this.headerKey += 1;
      console.log(this.login)
    }
  }
  
}
</script>

